// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, Suspense, forwardRef, useEffect, useMemo, useRef, useState } from 'react';

import { Badge } from '@mui/material';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';

import { CurrentView } from '../../../lib/componentTypes/context';
import { colors } from '../../../lib/designSystem';
import { parseString } from '../../../lib/html';
import { isInExploration } from '../../../lib/navigation';
import { LeveledMessage } from '../../../lib/notificationUtils';
import objectId from '../../../lib/objectId';
import useResizeObserver from '../../../lib/useResizeObserver';
import { isSensitivityAnalysis } from '../../../lib/workflowUtils';
import { useWorkflowState } from '../../../recoil/workflowState';
import { usePerTabWarnings } from '../../../state/external/project/validator';
import { DEFAULT_EXPANDED_RAIL_WIDTH, useSetAssistantSideRailWidth } from '../../../state/internal/assistant/assistantSideRailSize';
import { useCurrentView, useIsAnalysisView } from '../../../state/internal/global/currentView';
import { ActionButton } from '../../Button/ActionButton';
import OutputChartPanel from '../../OutputChart/OutputChartPanel';
import suspenseWidget from '../../SuspenseWidget';
import { createStyles, makeStyles } from '../../Theme';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { useAddNewAssistantMessage } from '../../hooks/assistant/useAddNewAssistantMessage';
import { useAssistantSend } from '../../hooks/assistant/useAssistantSend';
import { useAssistantEnabled } from '../../hooks/useAssistantEnabled';
import { AlertIcon } from '../../notification/AlertIcon';
import { ChartLineIcon } from '../../svg/ChartLineIcon';
import { SparkleDoubleIcon } from '../../svg/SparkleDoubleIcon';
import { LoadingEllipsis } from '../../visual/LoadingEllipsis';
import { Resizable } from '../Resizable';

const defaultBackground = colors.surfaceDark1;

const useBadgeStyles = makeStyles(
  () => createStyles({
    badge: {
      background: colors.red500,
      fontSize: '9px',
      height: '15px',
      minWidth: '15px',
      width: '15px',
      position: 'absolute',
      top: '-5px',
      right: '-7px',
    },
  }),
  { name: 'FooterBadge' },
);

export const SPLITTER_HEIGHT = 5;

const useStyles = makeStyles(
  () => createStyles({
    alertsBar: {
      width: '100%',
      height: 'fit-content',
      display: 'flex',
      flexDirection: 'row',
      gap: '8px',
      padding: '2px 12px 4px 20px',
      fontSize: '12px',
      justifyContent: 'flex-start',
      backgroundColor: defaultBackground,
      // must be higher than the zIndex of the Resizable component
      zIndex: 200,
    },
    splitter: {
      height: `${SPLITTER_HEIGHT}px`,
      width: '100%',
    },
    resizableContent: {
      width: '100%',
      height: '100%',
      flex: '0 0 auto',
      backgroundColor: defaultBackground,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    tabContent: {
      padding: '12px 20px',
      backgroundColor: defaultBackground,
      overflow: 'auto',
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      fontSize: '13px',
      '&.noPadding': {
        padding: '0px',
      },
    },

    // TODO: Move the below classes to a separate component
    messageAndAction: {
      display: 'flex',
      gap: '16px',
    },
    message: {
      display: 'flex',
      gap: '4px',
      alignItems: 'baseline',
      cursor: 'pointer',
      paddingLeft: '4px',
      lineHeight: '22px',

      '&:hover': {
        textDecoration: 'underline',
        '& $autoFix': {
          opacity: 1,
        },
      },
      '&.active': {
        backgroundColor: colors.purple500,
        color: 'white',
        textDecoration: 'none',
        '&:hover': {
          backgroundColor: colors.purple600,
        },
      },
    },
    alertIcon: {
      position: 'relative',
      top: '1px',
    },
    autoFix: {
      transition: 'opacity 250ms',
      opacity: 0,
    },
  }),
  { name: 'FooterBase' },
);

type InfoFooterItem = {
  title: ReactNode;
  icon: ReactNode;
  content: ReactNode;
  // if true, the content will have its own padding, otherwise,
  // the info footer will provide padding
  customPadding?: boolean;
};

type InfoFooterProps = {
  items: InfoFooterItem[];
  initialOpenTabIndex?: number;
};

/**
 * Things we need
 * - Alerts bar at the very bottom. This has a fixed height and displays small icons or text
 * - When an alert is clicked, a new panel opens up in the footer with more information
 *
 * The footer is resizable
 * - The splitter is at the top of the footer. Double-clicking the splitter will open or close it
 * - The footer has 'tabs' that can be clicked to open different panels
 * - Each tab has a title, and a content area which can be structured however we want
 * - Each tab also has a related icon which is displayed in the alerts bar.
 *    - Clicking the icon will open the tab related to it.
 */

const DEFAULT_OPEN_SIZE = '30%';
const COLLAPSE_THRESHOLD = 80;
export const DEFAULT_CLOSE_SIZE = '36px';

export const FooterBase = forwardRef((props: InfoFooterProps, ref) => {
  // == Props
  const { items, initialOpenTabIndex = 0 } = props;

  // == Hooks
  const classes = useStyles();

  // == State
  const currentView = useCurrentView();
  const resizableContentRef = useRef<HTMLDivElement>(null);
  const resizableContentSize = useResizeObserver(resizableContentRef);
  const boundingRef = ref as React.MutableRefObject<HTMLDivElement | null>;
  const [collapsed, setCollapsed] = useState(currentView !== CurrentView.ANALYSIS);
  const [windowHeight, setWindowHeight] = useState(
    collapsed ? DEFAULT_CLOSE_SIZE : DEFAULT_OPEN_SIZE,
  );
  const [activeTab, setActiveTab] = useState(initialOpenTabIndex);

  const handleAlertClick = (index: number) => {
    if (collapsed) {
      // If the footer was expanded, and then was collapsed through dragging, the windowHeight
      // would still be the height as if it was expanded. So if a button is clicked at this point
      // to expand the footer, it won't expand unless we set the height to a slightly diff value.
      if (windowHeight === DEFAULT_OPEN_SIZE) {
        setWindowHeight('31%');
      } else {
        setWindowHeight(DEFAULT_OPEN_SIZE);
      }
    } else if (activeTab === index) {
      // The same hack as below. But here the problem is if the footer was collapsed and then was
      // expanded through dragging. So if we click the active button with the intent to collapse,
      // we have to provide a slightly different value for the collapsed state (to trigger rerender)
      if (windowHeight === DEFAULT_CLOSE_SIZE) {
        setWindowHeight('37px');
      } else {
        setWindowHeight(DEFAULT_CLOSE_SIZE);
      }
    }
    setActiveTab(index);
  };

  const buttons = (
    <div className={classes.alertsBar}>
      {items.map((item, index) => (
        <ActionButton
          key={objectId(item)}
          kind="minimal"
          onClick={(ev) => {
            ev.stopPropagation();
            handleAlertClick(index);
          }}
          size="small">
          {item.icon}
        </ActionButton>
      ))}
    </div>
  );

  useEffect(() => {
    if (resizableContentSize.height > COLLAPSE_THRESHOLD) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  }, [resizableContentSize.height, setCollapsed]);

  const item = items[activeTab] ?? items[0];

  return (
    <>
      <Resizable
        clickSettings={{
          popOpenSize: DEFAULT_OPEN_SIZE,
          openOnClick: true,
          closeOnClick: true,
        }}
        collapseThreshold={COLLAPSE_THRESHOLD}
        getDragInputs={() => ({ boundingNode: boundingRef.current ?? undefined })}
        initialSize={windowHeight}
        maxSize={700}
        minSize={parseInt(DEFAULT_CLOSE_SIZE, 10)}
        splitterContent={<div className={classes.splitter} />}
        splitterPlacement="top"
        // higher zIndex needed for tooltip to be visible above main page
        zIndex={100}
        zIndexSplitter={100}>
        <div className={classes.resizableContent} ref={resizableContentRef}>
          {buttons}
          {!collapsed && (
            <div className={cx(classes.tabContent, { noPadding: item.customPadding })}>
              {item.content}
            </div>
          )}
        </div>
      </Resizable>
    </>
  );
});

export const InfoFooter = forwardRef((props, ref) => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();
  const { setSelection, setScrollTo, selectedNodeIds } = useSelectionContext();

  // == Hooks
  const classes = useStyles();
  const badgeClasses = useBadgeStyles();
  const location = useLocation();

  // == State
  const assistantEnabled = useAssistantEnabled();
  const { disabled: assistantSendDisabled } = useAssistantSend();
  const addNewAssistantMessage = useAddNewAssistantMessage(projectId);
  const setAssistantSideRailWidth = useSetAssistantSideRailWidth();
  const selectedSet = useMemo(() => new Set(selectedNodeIds), [selectedNodeIds]);
  const isAnalysisView = useIsAnalysisView();
  const workflow = useWorkflowState(projectId, workflowId);
  const isExploration = isInExploration(location.pathname);
  const isSensitivity = isExploration && !!workflow && isSensitivityAnalysis(workflow);

  const showOutputPanel = isAnalysisView && !isSensitivity;

  const warnings = usePerTabWarnings(projectId, workflowId, jobId);
  const simWarnings: { id: string; message: LeveledMessage }[] = [];
  warnings.forEach((value, key) => {
    value.forEach((message) => {
      simWarnings.push({ id: key, message });
    });
  });

  const handleMessageSelect = (id: string, message: LeveledMessage) => {
    setSelection([id]);
    setScrollTo({
      node: id,
      fast: true,
    });
  };

  const simWarningsContent = (
    simWarnings.map(({ id, message }) => (
      <div
        className={cx(classes.message, { active: selectedSet.has(id) })}
        key={message.message}
        onClick={() => {
          handleMessageSelect(id, message);
        }}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter' || ev.key === ' ') {
            handleMessageSelect(id, message);
          }
        }}
        role="button"
        tabIndex={0}>
        <div className={classes.alertIcon}>
          <AlertIcon level={message.level} />
        </div>
        <div className={classes.messageAndAction}>
          {parseString(message.message)}
          {assistantEnabled && (
            <div className={classes.autoFix}>
              <ActionButton
                compact
                disabled={assistantSendDisabled}
                kind="secondary"
                onClick={(event) => {
                  // Do not propagate, because we don't want to highlight the row when the button
                  // is clicked
                  event.stopPropagation();
                  setAssistantSideRailWidth(DEFAULT_EXPANDED_RAIL_WIDTH);
                  addNewAssistantMessage(`I need help fixing this error: ${message.message}`);
                }}
                size="small"
                title={assistantSendDisabled ? (
                  <>Assistant is busy<LoadingEllipsis /></>
                ) : ''}>
                <SparkleDoubleIcon color={colors.primaryInteractive} maxHeight={12} maxWidth={12} />
                Auto Fix
              </ActionButton>
            </div>
          )}
        </div>
      </div>
    ))
  );

  let initialOpenTabIndex = 0;

  const baseProps: InfoFooterItem[] = [
    {
      title: <>Simulation Issues ({simWarnings.length})</>,
      icon: (
        <Badge badgeContent={simWarnings.length} classes={badgeClasses}>
          Simulation Issues
        </Badge>
      ),
      content: (
        <>
          {simWarningsContent}
        </>
      ),
    },
  ];

  if (showOutputPanel) {
    initialOpenTabIndex = 1;
    baseProps.push({
      title: 'Output Chart',
      icon: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <ChartLineIcon color={colors.purple800} maxHeight={12} maxWidth={12} />
          Output Chart
        </div>
      ),
      content: (
        <Suspense fallback={suspenseWidget}>
          <OutputChartPanel />
        </Suspense>
      ),
      customPadding: true,
    });
  }

  return <FooterBase initialOpenTabIndex={initialOpenTabIndex} items={baseProps} ref={ref} />;
});
