// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo } from 'react';

import { SimulationRowProps } from '../../../../lib/componentTypes/simulationTree';
import { isGroupVisible } from '../../../../lib/entityGroupUtils';
import { assembleMenuSections, filteredMenuItems } from '../../../../lib/menuUtil';
import { deleteTreeNodeMenuItem, visibilityToggleTreeNodeMenuItem } from '../../../../lib/treeUtils';
import { useEntityGroupMap } from '../../../../recoil/entityGroupState';
import { useLcVisEnabledValue } from '../../../../recoil/lcvis/lcvisEnabledState';
import { useLcvisVisibilityMapValue } from '../../../../recoil/lcvis/lcvisVisibilityMap';
import { useSelectedGeometry } from '../../../../recoil/selectedGeometry';
import useMeshMultiPart from '../../../../recoil/useMeshingMultiPart';
import { useSetRefinementRegionVisibility } from '../../../../recoil/useRefinementRegions';
import { useToggleVisibility } from '../../../../recoil/vis/useToggleVisibility';
import { useIsGeometryView } from '../../../../state/internal/global/currentView';
import { useParaviewContext } from '../../../Paraview/ParaviewManager';
import VisibilityButton from '../../../Paraview/VisibilityButton';
import { useProjectContext } from '../../../context/ProjectContext';
import { useNodeDeletion } from '../../../hooks/useNodeDeletion';
import { TreeRow } from '../../TreeRow';

import { getNodeTypeIcon } from '@/lib/simulationTree/nodeIcon';
import environmentState from '@/state/environment';

export interface GeometryOrMeshRowProps extends SimulationRowProps {
  isGeometry: boolean;
}

// A top level row for all the geometry or mesh surfaces.
export const GeometryOrMeshRow = (props: GeometryOrMeshRowProps) => {
  // == Props
  const { isGeometry, node } = props;

  // == Contexts
  const { visibilityMap, viewState } = useParaviewContext();
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Recoil
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisReady = environmentState.use.lcvisReady;
  const visibilityV2 = useLcvisVisibilityMapValue({ projectId, workflowId, jobId });
  const setRefinementRegionVis = useSetRefinementRegionVisibility(projectId);
  const multiPartMeshing = useMeshMultiPart(projectId, workflowId, jobId);
  const isGeometryView = useIsGeometryView();
  const [selectedGeometry] = useSelectedGeometry(projectId);

  // == Hooks
  const { canDelete, deleteGeometryNode, deleteMeshNode, postDeleteNodeIds } = useNodeDeletion();

  // == Data
  const surfaceGroups = entityGroupMap.getGroups();

  const refinementRegionIds = useMemo(
    () => (
      multiPartMeshing?.refinementParams ?? []
    ).map((region) => region.id),
    [multiPartMeshing],
  );

  // Check if any of the groups are visible.
  const isVisible = surfaceGroups.some(
    (group) => isGroupVisible(
      lcvisEnabled ? visibilityV2 : visibilityMap,
      entityGroupMap,
      group.id,
    ),
  );

  const toggleIds = useMemo(
    () => surfaceGroups.reduce((result, group) => result.add(group.id), new Set<string>()),
    [surfaceGroups],
  );
  const toggleVisHook = useToggleVisibility(toggleIds, isVisible);
  const visControlsDisabled = lcvisEnabled ? !lcvisReady : !viewState;
  const toggleVis = useCallback(() => {
    toggleVisHook();
    setRefinementRegionVis((oldVis) => {
      const newVis = { ...oldVis };
      refinementRegionIds.forEach((id) => {
        newVis[id] = !isVisible;
      });
      return newVis;
    });
  }, [toggleVisHook, setRefinementRegionVis, refinementRegionIds, isVisible]);

  const deleteRow = useCallback(() => {
    const deleted = isGeometry ? deleteGeometryNode() : deleteMeshNode();
    if (deleted) {
      postDeleteNodeIds([node.id]);
    }
  }, [deleteGeometryNode, deleteMeshNode, isGeometry, node.id, postDeleteNodeIds]);

  const getContextMenuItems = useCallback(() => {
    const visItems = [visibilityToggleTreeNodeMenuItem(isVisible, toggleVis, visControlsDisabled)];

    const isRootGeometryNode = !!selectedGeometry.geometryId;
    const crudItems = filteredMenuItems([
      {
        itemConfig: deleteTreeNodeMenuItem(deleteRow, !canDelete(node.type, node.id)),
        shouldShow: !isRootGeometryNode,
      },
    ]);

    return assembleMenuSections(visItems, crudItems);
  }, [
    isVisible,
    visControlsDisabled,
    toggleVis,
    canDelete,
    deleteRow,
    node.id,
    node.type,
    selectedGeometry.geometryId,
  ]);

  const visButton = (
    <VisibilityButton disabled={visControlsDisabled} isVisible={isVisible} onClick={toggleVis} />
  );

  return (
    <TreeRow
      {...props}
      getContextMenuItems={getContextMenuItems}
      primaryIcon={getNodeTypeIcon(props.node.type)}
      propertiesControl={isGeometry && !isGeometryView}
      visibilityButton={visButton}
    />
  );
};
