// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement, useCallback, useLayoutEffect, useRef } from 'react';

import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { AnyKeyboardEvent } from '../../lib/event';
import { isEventTrigger } from '../../lib/keyBindings';
import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import { projectHasMotion } from '../../lib/motionDataUtils';
import { Logger } from '../../lib/observability/logs';
import { useUserCanEdit } from '../../lib/projectRoles';
import { NodeType } from '../../lib/simulationTree/node';
import { ListenerEvent, useEventListener } from '../../lib/useEventListener';
import useResizeObserver from '../../lib/useResizeObserver';
import { VIEWER_PADDING, getFilterNodeIcon } from '../../lib/visUtils';
import * as ParaviewRpc from '../../pvproto/ParaviewRpc';
import { useLcVisEnabledState } from '../../recoil/lcvis/lcvisEnabledState';
import { useAnyFiltersPending } from '../../recoil/lcvis/lcvisFilterStatus';
import { useEditState, useIsEditingFilterType } from '../../recoil/paraviewState';
import { useMeshReadyState } from '../../recoil/useMeshReadyState';
import useProjectMetadata from '../../recoil/useProjectMetadata';
import { useSimulationParam } from '../../state/external/project/simulation/param';
import { DEFAULT_STATE_GROUPS_WIDTHS, DEFAULT_STATE_HIDDEN_GROUPS, useToolbarGroupsWidths, useToolbarHiddenGroups, useToolbarMaxWidthValue } from '../../state/internal/component/toolbar';
import { useIsAnalysisView, useIsGeometryView, useIsSetupOrAdvancedView } from '../../state/internal/global/currentView';
import { useMotionAnimationPlaying, useShowMotionAnimationSettings } from '../../state/internal/vis/motionAnimation';
import { OverlayMode, useOverlayMode } from '../../state/internal/vis/overlayMode';
import { useWorkflowFlagValue } from '../../workflowFlag';
import Dropdown from '../Dropdown';
import { FloatingGroup } from '../Pane/FloatingGroup';
import { useParaviewContext } from '../Paraview/ParaviewManager';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { useLcVisButtonData } from '../hooks/ribbonToolbar/useLcVisButtonData';
import { useNodeGrouping } from '../hooks/useNodeGrouping';
import { useGetFilterMenuItem } from '../visFilter/useGetFilterMenuItem';
import { useHandleLcVisFilterClick } from '../visFilter/useHandleLcVisFilterClick';
import { useHandleProbeClick, useHandlePvFilterClick } from '../visFilter/useHandlePvFilterClick';
import { useIsFilterDisabled } from '../visFilter/useIsFilterDisabled';
import { useVisFilterParentNode } from '../visFilter/useVisFilterParentNode';

import { LcVisToolbarButton, getIconName } from './LcVisToolbarButton';
import { ToolbarButton, ToolbarButtonProps } from './ToolbarButton';

import environmentState from '@/state/environment';

// Toolbar height in pixels.
export const TOOLBAR_HEIGHT = 36;

const logger = new Logger('Toolbar');

/** Sum numeric values in an object. If `exclude` is passed, these props won't be part of the sum */
function sumObjectValues(
  obj: typeof DEFAULT_STATE_GROUPS_WIDTHS,
  exclude: Array<keyof typeof DEFAULT_STATE_HIDDEN_GROUPS>,
) {
  return Object.entries(obj).reduce((acc, [key, value]) => (
    exclude.includes(key as keyof typeof DEFAULT_STATE_HIDDEN_GROUPS) ? acc : acc + value
  ), 0);
}

// The toolbar appears at the top of the main panel.
const Toolbar = () => {
  // == Contexts
  const { paraviewClientState } = useParaviewContext();
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == Recoil
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const [
    showMotionAnimationSettings,
    setShowMotionAnimationSettings,
  ] = useShowMotionAnimationSettings();
  const [motionAnimationPlaying] = useMotionAnimationPlaying();
  const isFilterDisabled = useIsFilterDisabled();
  const handlePvFilterClick = useHandlePvFilterClick();
  const handleLcvButtonClick = useHandleLcVisFilterClick();
  const handleProbeClick = useHandleProbeClick();
  const getFilterMenuItem = useGetFilterMenuItem();
  const [overlayMode] = useOverlayMode();
  const { isTreeModal, selectedNode, selectedNodeIds } = useSelectionContext();
  const isAnalysisView = useIsAnalysisView();
  const isGeometryView = useIsGeometryView();
  const isSetupOrAdvancedView = useIsSetupOrAdvancedView();
  const [editState] = useEditState();
  const meshReadyState = useMeshReadyState(projectId, workflowId, jobId);
  const [lcVisEnabled] = useLcVisEnabledState(projectId);
  const lcVisReady = environmentState.use.lcvisReady;
  const toolbarMaxWidth = useToolbarMaxWidthValue({ projectId, workflowId, jobId });
  const [groupsWidths, setGroupsWidths] = useToolbarGroupsWidths({ projectId, workflowId, jobId });
  const [
    toolbarHiddenGroups,
    setToolbarHiddenGroups,
  ] = useToolbarHiddenGroups({ projectId, workflowId, jobId });
  const anyFiltersPending = useAnyFiltersPending();

  // -- State
  const mainButtonsRef = useRef<HTMLDivElement>(null);
  const mainFiltersRef = useRef<HTMLDivElement>(null);
  const moreFiltersRef = useRef<HTMLDivElement>(null);
  const toolboxRef = useRef<HTMLDivElement>(null);
  const mainButtonsWidth = useResizeObserver(mainButtonsRef).width;
  const mainFiltersWidth = useResizeObserver(mainFiltersRef).width;
  const moreFiltersWidth = useResizeObserver(moreFiltersRef).width;
  const toolboxWidth = useResizeObserver(toolboxRef).width;

  const workflowFlag = useWorkflowFlagValue();
  const lcVisButtonData = useLcVisButtonData();

  const {
    canGroupSelectedNodes,
    canUngroupSelectedNode,
    groupCameras,
    groupEntities,
    ungroupCameras,
    ungroupEntities,
    areSelectedNodesCameras,
  } = useNodeGrouping();

  const group = useCallback((nodeIds) => {
    if (areSelectedNodesCameras) {
      groupCameras(nodeIds).catch((error) => {
        logger.error(`Grouping cameras ${nodeIds.join(', ')} failed: `, error);
      });
    } else {
      groupEntities(nodeIds);
    }
  }, [areSelectedNodesCameras, groupCameras, groupEntities]);

  const ungroup = useCallback((nodeId) => {
    if (selectedNode?.type === NodeType.CAMERA_GROUP || selectedNode?.type === NodeType.CAMERA) {
      ungroupCameras(nodeId).catch((error) => {
        logger.error(`Ungrouping camera ${nodeId} failed: `, error);
      });
    } else {
      ungroupEntities(nodeId);
    }
  }, [selectedNode, ungroupCameras, ungroupEntities]);

  const clientDisconnected = paraviewClientState.client === null;

  const projectMetadata = useProjectMetadata(projectId);
  const userCanEdit = useUserCanEdit(projectMetadata?.summary);

  // Only show the animation button if we are in the setup tab and if motion has been imposed.
  const canAnimateMotion = isSetupOrAdvancedView && projectHasMotion(simParam);

  // The parent to which the new node will be added as a child.
  const parentNode = useVisFilterParentNode();

  // handler for group and ungroup keyboard events
  const handleToolbarShortcuts = useCallback((event: AnyKeyboardEvent) => {
    if ((readOnly && !areSelectedNodesCameras) || !userCanEdit) {
      return;
    }
    if (isEventTrigger('ungroup', event)) {
      // ungroup surfaces
      // by default cmd + g pops up the Find text in the browser, we want to prevent that.
      event.preventDefault();
      // only perform the function once per keydown sequence
      // (ignore multiple events if held down).
      if (event.repeat || !canUngroupSelectedNode) {
        return;
      }
      ungroup(selectedNodeIds[0]);
    } else if (isEventTrigger('group', event)) {
      event.preventDefault();
      if (event.repeat || !canGroupSelectedNodes) {
        return;
      }
      group(selectedNodeIds);
    }
  }, [
    selectedNodeIds,
    readOnly,
    group,
    ungroup,
    userCanEdit,
    areSelectedNodesCameras,
    canGroupSelectedNodes,
    canUngroupSelectedNode,
  ]);

  // add event listener for keyboard shortcuts for grouping and ungrouping.
  useEventListener(
    'keydown',
    (event: ListenerEvent) => handleToolbarShortcuts(event as AnyKeyboardEvent),
  );

  useLayoutEffect(() => {
    setGroupsWidths((old) => ({ ...old, mainButtons: mainButtonsWidth }));
  }, [mainButtonsWidth, setGroupsWidths]);

  useLayoutEffect(() => {
    setGroupsWidths((old) => ({ ...old, mainFilters: mainFiltersWidth }));
  }, [mainFiltersWidth, setGroupsWidths]);

  useLayoutEffect(() => {
    setGroupsWidths((old) => ({ ...old, moreFilters: moreFiltersWidth }));
  }, [moreFiltersWidth, setGroupsWidths]);

  useLayoutEffect(() => {
    setGroupsWidths((old) => ({ ...old, toolbox: toolboxWidth }));
  }, [toolboxWidth, setGroupsWidths]);

  const isEditing = useIsEditingFilterType();

  const renderFilterButton = (
    type: ParaviewRpc.TreeNodeType,
    key: string,
    title: string,
    earlyAccess = false,
  ) => (
    <ToolbarButton
      disabled={isTreeModal || isFilterDisabled(type)}
      earlyAccess={earlyAccess}
      engaged={isEditing(type)}
      icon={{ name: getFilterNodeIcon(type) }}
      key={key}
      locator={`toolbar-${key}`}
      onClick={() => handlePvFilterClick(type)}
      title={title}
    />
  );

  const mainButtons: ReactElement[] = [];
  const mainFilters: ReactElement[] = [];
  const moreFilters: ReactElement[] = [];
  const toolboxItems: CommonMenuItem[] = [];

  // Enable users with access to lcvis to switch between lcvis and Paraview
  // Don't even show the toggle if in simulation results view.
  if (!workflowFlag && lcVisButtonData) {
    mainButtons.push(
      <ToolbarButton {...lcVisButtonData} />,
    );
    toolboxItems.push({
      disabled: lcVisButtonData.disabled,
      label: lcVisEnabled ?
        'Switch to server-side rendering' :
        'Switch to client-side rendering',
      startIcon: lcVisButtonData.icon,
      onClick: lcVisButtonData.onClick,
    });
  }

  if (!workflowFlag && !lcVisEnabled && parentNode && meshReadyState) {
    if (canAnimateMotion) {
      const title = editState ?
        'The motion animation cannot be activated while editing a visualization' :
        'Motion Animation';
      const disabled = clientDisconnected || showMotionAnimationSettings || editState !== null;
      // The button to show the animation settings panel is disabled if there's an animation being
      // displayed, if the client is disconnected or while editState is active. In the latter case,
      // we need to disable the button because while we edit filters we may display widgets and
      // we don't want the widgets to be displayed at the same time the animation is playing.
      mainButtons.push(
        <ToolbarButton
          disabled={disabled}
          icon={{ name: 'play' }}
          key="motion-animation"
          locator="toolbar-motion-animation"
          onClick={() => {
            // Show the panel.
            setShowMotionAnimationSettings(true);
          }}
          title={title}
        />,
      );
      toolboxItems.push({
        disabled,
        label: title,
        startIcon: { name: 'play' },
        onClick: () => setShowMotionAnimationSettings(true),
      });
    }

    if (!workflowFlag && !isGeometryView) {
      mainFilters.push(
        <ToolbarButton
          disabled={clientDisconnected}
          engaged={overlayMode === OverlayMode.PROBE}
          icon={{ name: 'probe' }}
          key="probe"
          locator="toolbar-probe"
          onClick={() => handleProbeClick()}
          title="Probe"
        />,
        // We use the CLIP type for both plane and box clip.
        // This allows us to create a row named Clip and swap between clip types.
        // We differentiate between clip types in handler code based on params.
        renderFilterButton(
          ParaviewRpc.TreeNodeType.CLIP,
          'clip',
          'Clip',
        ),
      );
      toolboxItems.length && toolboxItems.push({ separator: true });
      toolboxItems.push(
        {
          disabled: clientDisconnected,
          label: 'Probe',
          startIcon: { name: 'probe' },
          onClick: handleProbeClick,
        },
        getFilterMenuItem('Clip', ParaviewRpc.TreeNodeType.CLIP),
      );

      // Enable Probe, Contour and Threshold with scalars and vectors.
      const hasData = parentNode.pointData.some((item) => item.dim === 1 || item.dim === 3);

      if (isAnalysisView && hasData) {
        const sliceItems = [
          getFilterMenuItem('Slice', ParaviewRpc.TreeNodeType.SLICE),
          getFilterMenuItem('MultiSlice', ParaviewRpc.TreeNodeType.MULTI_SLICE),
        ];
        mainFilters.push(
          <Dropdown
            key="toolbar-slice-dropdown"
            menuItems={sliceItems}
            position="below-left"
            toggle={(
              <ToolbarButton
                disabled={isTreeModal}
                dropdown
                icon={{ name: getFilterNodeIcon(ParaviewRpc.TreeNodeType.SLICE) }}
                key="slice-dropdown"
                locator="toolbar-slice-dropdown"
                onClick={() => { }}
              />
            )}
          />,
        );
        toolboxItems.push(...sliceItems);
      } else {
        mainFilters.push(
          renderFilterButton(
            ParaviewRpc.TreeNodeType.SLICE,
            'slice',
            'Slice',
          ),
        );
        toolboxItems.push(getFilterMenuItem('Slice', ParaviewRpc.TreeNodeType.SLICE));
      }

      if (isAnalysisView) {
        if (hasData) {
          mainFilters.push(
            renderFilterButton(
              ParaviewRpc.TreeNodeType.THRESHOLD,
              'threshold',
              'Threshold',
            ),
          );
          // Contours are displayed to the user as "Isosurface".
          mainFilters.push(
            renderFilterButton(
              ParaviewRpc.TreeNodeType.CONTOUR,
              'contour',
              'Isosurface',
            ),
          );
          toolboxItems.push(
            getFilterMenuItem('Threshold', ParaviewRpc.TreeNodeType.THRESHOLD),
            getFilterMenuItem('Isosurface', ParaviewRpc.TreeNodeType.CONTOUR),
          );
        }

        // Streamlines, surface LIC and glyph work only for vector arrays.
        if (parentNode.pointData.some((item) => item.dim === 3)) {
          moreFilters.push(
            renderFilterButton(
              ParaviewRpc.TreeNodeType.STREAMLINES,
              'streamlines',
              'Streamlines',
            ),
          );
          moreFilters.push(
            renderFilterButton(
              ParaviewRpc.TreeNodeType.SURFACE_L_I_C,
              'surfacelic',
              'SurfaceLIC',
            ),
          );
          moreFilters.push(
            renderFilterButton(
              ParaviewRpc.TreeNodeType.GLYPH,
              'glyph',
              'Vector',
            ),
          );
          toolboxItems.push(
            { separator: true },
            getFilterMenuItem('Streamlines', ParaviewRpc.TreeNodeType.STREAMLINES),
            getFilterMenuItem('SurfaceLIC', ParaviewRpc.TreeNodeType.SURFACE_L_I_C),
            getFilterMenuItem('Vector', ParaviewRpc.TreeNodeType.GLYPH),
          );
        }
      }
      toolboxItems.push(
        { separator: true },
        { title: 'More Filters' },
      );

      if (isAnalysisView && hasData) {
        toolboxItems.push(
          getFilterMenuItem('Line', ParaviewRpc.TreeNodeType.LINE, true),
          getFilterMenuItem(
            'Intersection Curve',
            ParaviewRpc.TreeNodeType.INTERSECTION_CURVE,
            true,
          ),
        );
      }

      toolboxItems.push(
        getFilterMenuItem('Extract Surfaces', ParaviewRpc.TreeNodeType.EXTRACT_SURFACES),
      );
    }
  }

  if (!workflowFlag && lcVisEnabled) {
    if (canAnimateMotion) {
      const onClickStop = async () => {
        const display = await lcvHandler.getDisplay();
        display?.getWorkspace()?.stopAnimation();
      };
      const onClickPlay = () => setShowMotionAnimationSettings(true);

      const playButtonProps: ToolbarButtonProps = motionAnimationPlaying ? {
        icon: { name: 'stopOutline' },
        onClick: onClickStop,
        locator: 'toolbar-stop-motion-animation',
        title: 'Stop Motion Preview',
      } : {
        icon: { name: 'playOutline' },
        onClick: onClickPlay,
        locator: 'toolbar-motion-animation',
        title: 'Motion Preview',
      };
      playButtonProps.disabled = (showMotionAnimationSettings || editState !== null || !lcVisReady);
      mainButtons.push(
        <ToolbarButton
          key={motionAnimationPlaying ? 'stop-motion-animation' : 'motion-animation'}
          {...playButtonProps}
        />,
      );
      toolboxItems.push(
        {
          disabled: playButtonProps.disabled,
          label: playButtonProps.title as string,
          startIcon: { name: playButtonProps.icon.name },
          onClick: motionAnimationPlaying ? onClickStop : onClickPlay,
        },
        { separator: true },
      );
    }

    mainFilters.push(
      <LcVisToolbarButton
        disabled={!lcVisReady || anyFiltersPending}
        earlyAccess={false}
        engaged={isEditing(ParaviewRpc.TreeNodeType.CLIP)}
        id="lcvisClip"
        key="lcvisClip"
        onClick={handleLcvButtonClick}
        title="Clip"
        type="clip"
      />,
      <LcVisToolbarButton
        disabled={!lcVisReady || anyFiltersPending}
        earlyAccess={false}
        engaged={isEditing(ParaviewRpc.TreeNodeType.SLICE)}
        id="lcvisSlice"
        key="lcvisSlice"
        onClick={handleLcvButtonClick}
        title="Slice"
        type="slice"
      />,
    );
    toolboxItems.push(
      {
        disabled: !lcVisReady || anyFiltersPending,
        engaged: isEditing(ParaviewRpc.TreeNodeType.CLIP),
        label: 'Clip',
        startIcon: { name: getIconName('clip') },
        onClick: (event) => handleLcvButtonClick(event, 'clip'),
      },
      {
        disabled: !lcVisReady || anyFiltersPending,
        engaged: isEditing(ParaviewRpc.TreeNodeType.SLICE),
        label: 'Slice',
        startIcon: { name: getIconName('slice') },
        onClick: (event) => handleLcvButtonClick(event, 'slice'),
      },
      {
        disabled: !lcVisReady,
        label: 'Probe',
        startIcon: { name: getIconName('probe') },
        onClick: (event) => handleLcvButtonClick(event, 'probe'),
      },
      {
        disabled: !lcVisReady,
        label: 'Measure',
        startIcon: { name: getIconName('measure') },
        onClick: (event) => handleLcvButtonClick(event, 'measure'),
      },
    );

    // Add analysis view filters, as we implement these filters in LCVis the buttons
    // can be enabled
    if (isAnalysisView) {
      const needServerSideRendering = 'Coming soon, currently requires server-side rendering';
      mainFilters.push(
        <LcVisToolbarButton
          disabled
          earlyAccess={false}
          engaged={false}
          id="lcvisMultiSlice"
          key="MultiSlice"
          onClick={handleLcvButtonClick}
          title={`MultiSlice (${needServerSideRendering})`}
          type="multislice"
        />,
        <LcVisToolbarButton
          disabled={!lcVisReady}
          earlyAccess={false}
          engaged={false}
          id="lcvisThreshold"
          key="lcvisThreshold"
          onClick={(event) => handleLcvButtonClick(event, 'threshold')}
          title="Threshold"
          type="threshold"
        />,
        <LcVisToolbarButton
          // Note: should also be disabled if we don't have data ready
          disabled={!lcVisReady}
          earlyAccess={false}
          engaged={false}
          id="lcvisContour"
          key="lcvisContour"
          onClick={(event) => handleLcvButtonClick(event, 'contour')}
          title="Isosurface"
          type="contour"
        />,
      );

      moreFilters.push(
        <LcVisToolbarButton
          disabled={!lcVisReady}
          earlyAccess={false}
          engaged={false}
          id="lcvisStreamlines"
          key="lcvisStreamlines"
          onClick={(event) => handleLcvButtonClick(event, 'streamlines')}
          title="Streamlines"
          type="streamlines"
        />,
        <LcVisToolbarButton
          disabled
          earlyAccess={false}
          engaged={false}
          id="lcvisSurfaceLIC"
          key="lcvisSurfaceLIC"
          onClick={handleLcvButtonClick}
          title={`Surface LIC (${needServerSideRendering})`}
          type="surfaceLIC"
        />,
        <LcVisToolbarButton
          disabled
          earlyAccess={false}
          engaged={false}
          id="lcvisGlyph"
          key="lcvisGlyph"
          onClick={(event) => handleLcvButtonClick(event, 'glyph')}
          title={`Vector (${needServerSideRendering})`}
          type="glyph"
        />,
      );
      toolboxItems.push(
        {
          disabled: true,
          label: 'MultiSlice',
          startIcon: { name: getIconName('multislice') },
          onClick: () => {},
          disabledReason: `${needServerSideRendering}`,
        },
        {
          disabled: true,
          label: 'Threshold',
          startIcon: { name: getIconName('threshold') },
          onClick: () => {},
          disabledReason: `${needServerSideRendering}`,
        },
        {
          disabled: true,
          label: 'Isosurface',
          startIcon: { name: getIconName('contour') },
          onClick: () => {},
          disabledReason: `${needServerSideRendering}`,
        },
        { separator: true },
        {
          disabled: true,
          label: 'Streamlines',
          startIcon: { name: getIconName('streamlines') },
          onClick: () => {},
          disabledReason: `${needServerSideRendering}`,
        },
        {
          disabled: true,
          label: 'Surface LIC',
          startIcon: { name: getIconName('surfaceLIC') },
          onClick: () => {},
          disabledReason: `${needServerSideRendering}`,
        },
        {
          disabled: true,
          label: 'Vector',
          startIcon: { name: getIconName('glyph') },
          onClick: () => {},
          disabledReason: `${needServerSideRendering}`,
        },
        { separator: true },
        { title: 'More Filters' },
        {
          disabled: true,
          label: 'Line',
          startIcon: { name: getIconName('intersectionLine') },
          onClick: () => {},
          disabledReason: `${needServerSideRendering}`,
          earlyAccess: true,
        },
        {
          disabled: true,
          label: 'Intersection Curve',
          startIcon: { name: getIconName('intersectionCurve') },
          onClick: () => {},
          disabledReason: `${needServerSideRendering}`,
          earlyAccess: true,
        },
      );
    }
  }

  useLayoutEffect(() => {
    if (!toolbarMaxWidth) {
      return;
    }

    const gaps = Object.values(groupsWidths).filter((width) => width).length - 1;

    // These are all the groups that can become hidden if there is not enough space in the toolbar,
    // starting from the first in the list (visually it's the last in the UI).
    const hideable: Array<keyof typeof DEFAULT_STATE_HIDDEN_GROUPS> = [
      'moreFilters', 'mainFilters', 'mainButtons',
    ];

    hideable.forEach((key, idx) => {
      const totalWidth = sumObjectValues(
        groupsWidths,
        hideable.slice(0, idx),
      ) + (gaps - idx) * VIEWER_PADDING;
      const hideGroup = toolbarMaxWidth < totalWidth;
      setToolbarHiddenGroups((old) => ({ ...old, [key]: hideGroup }));
    });
  }, [
    toolbarMaxWidth,
    groupsWidths,
    mainButtonsWidth,
    mainFiltersWidth,
    moreFiltersWidth,
    toolboxWidth,
    setToolbarHiddenGroups,
  ]);

  return (
    <>
      {mainButtons.length > 0 && !toolbarHiddenGroups.mainButtons && (
        <FloatingGroup ref={mainButtonsRef}>{mainButtons}</FloatingGroup>
      )}
      {mainFilters.length > 0 && !toolbarHiddenGroups.mainFilters && (
        <FloatingGroup ref={mainFiltersRef}>{mainFilters}</FloatingGroup>
      )}
      {moreFilters.length > 0 && !toolbarHiddenGroups.moreFilters && (
        <FloatingGroup ref={moreFiltersRef}>{moreFilters}</FloatingGroup>
      )}

      {!!toolboxItems.length && (
        <FloatingGroup ref={toolboxRef}>
          <Dropdown
            menuItems={toolboxItems}
            position="below-left"
            toggle={(
              <ToolbarButton
                disabled={isTreeModal}
                dropdown
                icon={{ name: 'fileBox' }}
                key="toolbox"
                locator="toolbar-toolbox"
                onClick={() => { }}
                title="Toolbox"
              />
            )}
          />
        </FloatingGroup>
      )}
    </>
  );
};

export default Toolbar;
