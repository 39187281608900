import React, { forwardRef, useLayoutEffect, useMemo, useRef, useState } from 'react';

import cx from 'classnames';
import Draggable from 'react-draggable';
import ReactMarkdown from 'react-markdown';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import 'katex/dist/katex.min.css';

import { ChatMessage } from '../../../lib/assistant/assistant';
import { colors } from '../../../lib/designSystem';
import { parseString } from '../../../lib/html';
import { useAssistantThinkingValue } from '../../../state/external/assistant/assistantThinking';
import { useIsGeometryView, useIsSetupView } from '../../../state/internal/global/currentView';
import { ActionButton } from '../../Button/ActionButton';
import { TextInput } from '../../Form/TextInput';
import { createStyles, makeStyles } from '../../Theme';
import Tooltip from '../../Tooltip';
import { useAssistantSend } from '../../hooks/assistant/useAssistantSend';
import { PaperAirplaneIcon } from '../../svg/PaperAirplaneIcon';
import { SparkleDoubleIcon } from '../../svg/SparkleDoubleIcon';

import { NestedForm, NestedFormData } from './Chat/NestedForm';
import { NestedNodeLinks, NestedNodeLinksData } from './Chat/NestedNodeLinks';
import { SuggestedList } from './Chat/SuggestedList';
import { FEEDBACKS, ThumbsFeedback } from './Chat/ThumbsFeedback';

import { AnimatedEllipsis } from '@/components/visual/AnimatedEllipsis';
import { useAssistantRespondingValue } from '@/state/external/assistant/assistantResponding';
import { MAX_MESSAGE_BOX_HEIGHT, MIN_MESSAGE_BOX_HEIGHT, useChatPanelHeight } from '@/state/internal/assistant/assistantSideRailSize';

type ChatProps = {
  messages: ChatMessage[];
  onSendMessage: (message: string) => void;
}

// These are custom nodes that can be passed in the response from the assistant. If they exist,
// we should parse a specially crafted component for each of them with the help of ReactMarkdown.
type CustomMarkdownComponents = ReactMarkdownOptions['components'] & {
  nodelinks?: React.ComponentType<{ children: string }>;
  parameterform?: React.ComponentType<{ children: string }>;
};

const DRAGGER_HEIGHT = 12;
const CHAT_GAP = 16;
const MAX_CHAT_WIDTH = 500;
const MIN_CHAT_WIDTH = 200;
const SUGGESTED_GEOMETRY_ACTIONS = [
  {
    items: [
      'Create a farfield box',
      'Create a farfield sphere',
      'Copy the farfield from another project',
    ],
  },
];

const SUGGESTED_SETUP_ACTIONS = [
  {
    label: 'Project Management',
    items: [
      'List my projects',
      'Summarize my simulation setup',
      'Start the simulation',
    ],
  },
  {
    label: 'Materials',
    items: [
      'Create a material',
      'Change my material to an incompressible fluid',
    ],
  },
  {
    label: 'Physics',
    items: [
      'Add fluid physics',
      'Change the turbulence model',
      'Initialize using farfield values',
    ],
  },
  {
    label: 'Boundary Conditions',
    items: [
      'Create a farfield boundary condition',
      'Update the velocity in the farfield',
    ],
  },
  {
    label: 'Reference frames',
    items: [
      'Create a reference frame',
      'Create a body frame',
    ],
  },
  {
    label: 'Meshing',
    items: [
      'Create minimal mesh for LMA',
    ],
  },
  {
    label: 'Question & Answering',
    items: [
      'What RANS turbulence models do Luminary Cloud support?',
      'How many boundary conditions are there in my setup?',
    ],
  },
];

const useStyles = makeStyles(
  () => createStyles({
    root: {
      width: '100%',
      maxWidth: `${MAX_CHAT_WIDTH}px`,
      minWidth: `${MIN_CHAT_WIDTH}px`,
      height: '100%',
      padding: `${CHAT_GAP}px`,
      backgroundColor: colors.neutral150,
      display: 'flex',
      gap: `${CHAT_GAP}px`,
      flexDirection: 'column',
      justifyContent: 'flex-end',
      overflow: 'hidden',
      position: 'relative',
    },
    chatHistory: {
      overflowY: 'auto',
      padding: '8px 0',
      display: 'flex',
      flexDirection: 'column',
      gap: `${CHAT_GAP}px`,
      flex: '1',
      fontSize: '13px',
    },
    inputBox: {
      display: 'flex',
      gap: '8px',
      position: 'relative',
      alignSelf: 'center',
    },
    spacer: {
      // Pushes the messages to the bottom
      flex: '1',
    },
    dragger: {
      left: '16px',
      height: `${DRAGGER_HEIGHT}px`,
      width: 'calc(100% - 32px)',
      position: 'absolute',
      zIndex: 1,
      cursor: 'row-resize',
      display: 'flex',
      alignItems: 'center',
    },
    draggerLine: {
      position: 'relative',
      width: '100%',
      height: '1px',
      backgroundColor: colors.neutral0,
    },
  }),
  { name: 'Chat' },
);

const useAssistantChatStyles = makeStyles(
  () => createStyles({
    assistantMessage: {
      backgroundColor: 'inherit',
      color: colors.lowEmphasisText,
      display: 'flex',
      flexDirection: 'row',
      gap: '6px',
      alignSelf: 'flex-start',
      alignItems: 'baseline',
      width: '100%',

      // When the message start to appear, if it is a math formula, we might end up in a case
      // where only a part of the formula is displayed. In that case, the message will have
      // "$$" only at the beginning of the message but not at the end, and remark/rehype will
      // treat the text as an error (not as a latex formula) and will display it in red color.
      // To prevent this transitional error state, we'll just hide the unfinished formula.
      '& .katex-error': {
        display: 'none',
      },

      '& table': {
        '& thead': {
          backgroundColor: colors.surfaceMedium1,
        },
        '& th, & td': {
          padding: '4px',
          borderBottom: `1px solid ${colors.neutral300}`,
        },
      },
    },
    assistantIcon: {
      height: '12px',
      width: '12px',
      flex: '0 0 auto',
    },
    response: {
      flex: 1,
    },
    responseControlBar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    preparingResponse: {
      fontStyle: 'italic',
    },
    markdown: {
      '& p': {
        marginTop: '0.9em',
        marginBottom: '0.75em',
      },
      '& ul, & ol': {
        marginTop: 0,
        marginBottom: 0,
        paddingLeft: '20px',
      },
      '& li': {
        marginBottom: '0.25em',
      },
      '& a': {
        textDecoration: 'none',
        transition: 'color 250ms, box-shadow 350ms',
      },
      '& a, & a:visited': {
        color: colors.primaryInteractive,

        '&:hover': {
          color: colors.primaryCta,
          boxShadow: `inset 0 -1px 0 0 ${colors.primaryCta}`,
        },
      },
    },
  }),
  { name: 'AssistantChat' },
);

const useUserChatStyles = makeStyles(
  () => createStyles({
    userMessage: {
      backgroundColor: colors.neutral300,
      color: colors.highEmphasisText,
      borderRadius: '8px',
      padding: '4px 8px',
      maxWidth: '80%',
      alignSelf: 'flex-end',
      textAlign: 'left',
      width: 'max-content',
    },
  }),
  { name: 'UserChat' },
);

type AssistantMessageProp = {
  text: string;
  isPreparing?: boolean,
  index?: number,
  isLast?: boolean,
}

const AssistantMessage = forwardRef<HTMLDivElement, AssistantMessageProp>((props, ref) => {
  // == Props
  const { index, text, isPreparing = false, isLast = false } = props;

  // == Hooks
  const classes = useAssistantChatStyles();

  // == State
  const isGeometryView = useIsGeometryView();
  const isSetupView = useIsSetupView();
  const assistantResponding = useAssistantRespondingValue();

  return (
    <div className={classes.assistantMessage} ref={ref}>
      <div className={classes.assistantIcon}>
        <SparkleDoubleIcon color={colors.purple800} maxHeight={12} maxWidth={12} />
      </div>
      {isPreparing ? (
        <span className={classes.preparingResponse}>
          {text}<AnimatedEllipsis />
        </span>
      ) : (
        <div className={classes.response}>
          <ReactMarkdown
            className={classes.markdown}
            components={{
              a: ({ children, href }) => (
                <a href={href} rel="noopener noreferrer" target="_blank">
                  {children}
                </a>
              ),
              nodelinks: ({ children }) => {
                try {
                  const content: NestedNodeLinksData = JSON.parse(children);
                  return content.links.length ? <NestedNodeLinks links={content.links} /> : null;
                } catch (err) {
                  return (
                    <p>An error occured while generating the links. Please contact support.</p>
                  );
                }
              },
              parameterform: ({ children }) => {
                try {
                  const formContent: NestedFormData = JSON.parse(children);
                  return formContent.entries.length ? <NestedForm data={formContent} /> : null;
                } catch (err) {
                  return (
                    <p>An error occured while generating the form. Please contact support.</p>
                  );
                }
              },
            } as CustomMarkdownComponents}
            rehypePlugins={[rehypeRaw, rehypeKatex]}
            remarkPlugins={[remarkMath, remarkGfm]}>
            {text}
          </ReactMarkdown>

          {/* Add some default options */}
          {isGeometryView && index === 0 && (
            <SuggestedList list={SUGGESTED_GEOMETRY_ACTIONS} />
          )}

          {isSetupView && index === 0 && (
            <SuggestedList list={SUGGESTED_SETUP_ACTIONS} />
          )}

          {index && index > 0 ? (
            <div className={classes.responseControlBar}>
              <div>
                {assistantResponding && isLast && !['!', '?', '.'].includes(text.slice(-1)) && (
                  <AnimatedEllipsis />
                )}
              </div>
              <ThumbsFeedback disabled={!isLast} />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
});

const UserMessage = forwardRef<HTMLDivElement, { text: string; isLastUserMessage?: boolean }>(
  ({ text, isLastUserMessage }, ref) => {
    const classes = useUserChatStyles();
    return (
      <div
        className={classes.userMessage}
        data-last-user-message={isLastUserMessage ? true : undefined}
        ref={ref}>
        {parseString(text)}
      </div>
    );
  },
);

const IGNORE_REGEX = /<hidden>([\s\S]*?)<\/hidden>/;

export const Chat = (props: ChatProps) => {
  // == Props
  const { onSendMessage } = props;

  // == Hooks
  const classes = useStyles();
  const chatHistoryRef = useRef<HTMLDivElement | null>(null);
  const recentUserMessageRef = useRef<HTMLDivElement | null>(null);
  const [currentMessage, setCurrentMessage] = useState('');
  const assistantThinking = useAssistantThinkingValue();
  const { disabled, disabledReason } = useAssistantSend();
  const [messageBoxHeight, setMessageBoxHeight] = useChatPanelHeight();

  // Applying `cursor: row-resize` only on the dragger causes flickering during resizing,
  // activating this state adds a CSS class, ensuring global styles apply the "moving" cursor
  // to all elements.
  const [isDraggerActive, setIsDraggerActive] = useState(false);

  // dragger position is relative to the initial size, requiring a reference to it
  const initialHeightRef = useRef(messageBoxHeight);
  const positionY = initialHeightRef.current - messageBoxHeight;

  const lastMessageIsUserFeedback = useMemo(() => (FEEDBACKS as unknown as string[]).includes(
    props.messages[props.messages.length - 1]?.text,
  ), [props.messages]);

  // We want to construct an array of clean messages - only the ones that we want to display
  const messages = useMemo(
    () => props.messages.filter((message) => {
      // When the assistant starts to generate a response, its first message is an empty text.
      // Then, after a few moments the empty message gets replaced with the actual response.
      // We don't want to show this empty message and receiving it ends the "is preparing" state so
      // it's better to just filter out these empty messages.
      if (message.text === '') {
        return false;
      }
      // The thumbs/up down buttons just send normal text to the assistant as a regular message.
      // But since we don't want to expose this to the user, we just ignore these messages.
      if ((FEEDBACKS as unknown as string[]).includes(message.text)) {
        return false;
      }
      // When we send a feedback (thumbs up/down or deselect feedback), the assistant will
      // return a <hidden>thank you...</hidden> message. We don't want to show this response
      // so we should ignore it.
      if (message.text.match(IGNORE_REGEX)) {
        return false;
      }
      return true;
    }),
    [props.messages],
  );

  useLayoutEffect(() => {
    if (chatHistoryRef.current) {
      // Do not use the scrollIntoView as it messes up the layout when the messages contain math
      // formulas and ReactMarkdown uses the rehype/remark plugins to format them.
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSubmit = () => {
    if (currentMessage.length > 0 && !disabled) {
      onSendMessage(currentMessage);
      setCurrentMessage('');
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.chatHistory} ref={chatHistoryRef}>
        <div className={classes.spacer} /> {/* Spacer to push messages to the bottom */}
        {messages.map((message, idx) => {
          if (message.isUser) {
            // Update recentUserMessageRef
            return (
              <UserMessage
                key={message.id}
                ref={recentUserMessageRef} // Now points to the last user message
                text={message.text}
              />
            );
          }

          // If not a user message, just render the assistant's message.
          return (
            <AssistantMessage
              // We want the feedback buttons to be active only for the last response because we
              // may confuse the assistant if we send feedback for some of the previous responses.
              index={idx}
              isLast={idx === messages.length - 1}
              key={message.id}
              text={message.text}
            />
          );
        })}

        {/* If the last message was a user feedback (thumbs up/down), we don't want to show anything
        in the chat (including the "processing...") because we want this feedback to be silent. */}
        {assistantThinking && !lastMessageIsUserFeedback && (
          <AssistantMessage
            isPreparing
            text="Preparing response"
          />
        )}
      </div>

      <Draggable
        axis="y"
        bounds={{
          top: (initialHeightRef.current - MAX_MESSAGE_BOX_HEIGHT),
          bottom: (initialHeightRef.current - MIN_MESSAGE_BOX_HEIGHT),
        }}
        onDrag={(_, { y }) => {
          setMessageBoxHeight(initialHeightRef.current - y);
        }}
        onStart={() => {
          setIsDraggerActive(true);
        }}
        onStop={() => {
          setIsDraggerActive(false);
        }}
        position={{ x: 0, y: positionY }}>
        <div
          className={cx(classes.dragger, isDraggerActive && 'vertical-dragger-active')}
          style={{
            top: `calc(100% - ${initialHeightRef.current}px - 28px - ${DRAGGER_HEIGHT / 2}px)`,
          }}>
          <div className={classes.draggerLine} />
        </div>
      </Draggable>

      <div
        className={classes.inputBox}
        style={{
          height: messageBoxHeight,
          // minHeight wins with flex: 1 property applied on a spacer
          minHeight: messageBoxHeight,
        }}>
        <Tooltip title={disabledReason}>
          <span>
            <TextInput
              adornmentButton={(
                <ActionButton
                  disabled={disabled || !currentMessage.length}
                  onClick={handleSubmit}
                  size="small">
                  <PaperAirplaneIcon maxHeight={12} maxWidth={12} /> Send
                </ActionButton>
              )}
              cols={MAX_CHAT_WIDTH / 10}
              disabled={!!disabledReason}
              fullHeight
              multiline
              onChange={(newValue: string) => {
                setCurrentMessage(newValue);
              }}
              onEnter={handleSubmit}
              placeholder="Enter a message"
              resize="none"
              submitOnEnter
              value={currentMessage}
            />
          </span>
        </Tooltip>
      </div>
    </div>
  );
};
